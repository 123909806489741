/** @jsx jsx */
import { jsx } from 'theme-ui'
import Img from 'gatsby-image'

import Layout from 'src/components/layout'
import SEO from '../components/seo'
import { graphql, Link } from 'gatsby'

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="404: страница не найдена" />
      <div
        sx={{
          maxWidth: 'content',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mx: 'auto',
          px: 3,
          textAlign: 'center',
        }}
      >
        <Img
          fluid={data.placeholder.childImageSharp.fluid}
          sx={{ width: '100%', maxWidth: '580px', my: 3 }}
        />
        <h1>Страница не найдена</h1>
        <p sx={{ mb: 7 }}>
          Мы не можем найти такой страницы на сайте{' '}
          <span role="img" aria-label="нам очень жаль">
            😔
          </span>{' '}
          <br /> Вы можете начать искать с главной.{' '}
        </p>
        <Link to="/" sx={{ variant: 'buttons.green', mb: 9, fontSize: [0, 1] }}>
          Перейти на главную
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fluid(maxWidth: 580, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
